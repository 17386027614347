(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Anchors = /** @class */ (function () {
    function Anchors() {
        var _this = this;
        this.items = [];
        this.nav = $('nav');
        this.parse = function () {
            $('.js-anchor').each(function (index, item) {
                var anchor;
                var anchorItem = $(item);
                if (!anchorItem.data('anchor')) {
                    var anchor_1 = new Anchor(anchorItem);
                    anchorItem.data('anchor', anchor_1);
                    _this.items.push(anchor_1);
                }
            });
            _this.hashchange(event);
        };
        this.hashchange = function (event) {
            var hash = window.location.hash.replace('#', '');
            _this.hash = hash;
            if (_this.hash != hash || !event) {
                if (hash) {
                    for (var i = 0; i < _this.items.length; i++) {
                        var item = _this.items[i];
                        if (item.id == hash) {
                            $(item.element.addClass('is-active'));
                            $('body, html').stop(true).animate({ 'scrollTop': item.target.offset().top - _this.nav.outerHeight() });
                        }
                        else {
                            $(item.element.removeClass('is-active'));
                        }
                    }
                }
            }
        };
        this.parse();
        $(window).on('hashchange', this.hashchange.bind(this));
    }
    return Anchors;
}());
var Anchor = /** @class */ (function (_super) {
    __extends(Anchor, _super);
    function Anchor(element) {
        var _this = _super.call(this) || this;
        _this.click = function (event) {
            if (_this.id == window.location.hash.replace('#', '')) {
                console.log(Anchors);
            }
        };
        _this.element = element;
        if (!_this.element.length)
            return _this;
        _this.element.on('click', _this.click.bind(_this));
        _this.id = _this.element.attr('href').split('#').pop();
        _this.target = $('#' + _this.id);
        _this.target.attr('id', 'js-' + _this.id);
        return _this;
    }
    return Anchor;
}(Anchors));
var anchors = new Anchors();
},{}],2:[function(require,module,exports){
var Contact = /** @class */ (function () {
    function Contact() {
        this.elements = $('.js-contactbtn');
        var startContact;
        $(".o-overlay").hide();
        this.elements.each(function (index, item) {
            var items = $(item);
            var startContact = new ContactForm(items);
        });
    }
    return Contact;
}());
var ContactForm = /** @class */ (function () {
    function ContactForm(items) {
        this.element = items;
        this.element.on("click", this.open.bind(this));
        $(".o-overlay").find('.js-closebtn').on("click", this.close.bind(this));
        // if(!this.lightbox.length) return;
    }
    ContactForm.prototype.open = function (event) {
        this.element.attr('data-send');
        event.preventDefault();
        $(".o-overlay").show();
        $('input[value="sendto"]').val(this.element.attr('data-send'));
    };
    ContactForm.prototype.close = function () {
        $('input[value="' + this.element.attr("data-send") + '"]').val("sendto");
        $(".o-overlay").hide();
    };
    return ContactForm;
}());
var contact = new Contact();
},{}],3:[function(require,module,exports){
var Faq = /** @class */ (function () {
    function Faq() {
        this.element = $('.js-faq');
        $('article').hide();
        $('article').first().show();
        $('.js-faq--topic').first().addClass('active');
        $('.js-accessibility').hide();
        this.topics = this.element.find('.js-faq--topics a');
        this.items = this.element.find('.js-item');
        var openItems;
        var openTopics;
        this.items.each(function (index, item) {
            var items = $(item);
            var openItems = new FaqItems(items);
        });
        this.topics.each(function (index, item) {
            var topic = $(item);
            var openTopics = new FaqTopics(topic);
        });
        $('#' + $('.js-faq--topic.active').attr('aria-controls')).find('.js-item').first().addClass('active');
    }
    return Faq;
}());
var FaqTopics = /** @class */ (function () {
    function FaqTopics(topic) {
        this.element = topic;
        this.element.on('click', this.open.bind(this));
    }
    // hideArticles() {
    //     $('article').removeClass('show');
    // }
    // deslectAllTabs() {
    //     $('js-faq--topics a').attr('aria-selected', false);
    // }
    FaqTopics.prototype.open = function (event) {
        event.preventDefault();
        var tab = '#' + this.element.attr('aria-controls');
        $('article').hide();
        $(tab).show();
        if (this.element.hasClass('active')) {
            this.element.removeClass('active');
        }
        else {
            $('.js-faq--topic.active').removeClass('active');
            this.element.addClass('active');
        }
    };
    return FaqTopics;
}());
var FaqItems = /** @class */ (function () {
    function FaqItems(items) {
        this.element = items;
        this.content = this.element.find('dd');
        this.element.on('mouseup', this.toggle.bind(this));
        this.element.removeClass('active');
    }
    FaqItems.prototype.toggle = function () {
        if (this.element.hasClass('active')) {
            this.element.removeClass('active');
        }
        else {
            $('.js-item.active').removeClass('active');
            this.element.addClass('active');
        }
    };
    FaqItems.prototype.close = function () {
    };
    return FaqItems;
}());
var faq = new Faq();
},{}],4:[function(require,module,exports){
var Lightbox = /** @class */ (function () {
    function Lightbox() {
        this.element = $('.js-lightbox');
        this.items = this.element.find('.js-item');
        var openItems;
        this.items.each(function (index, item) {
            var items = $(item);
            var openItems = new LightboxItems(items);
        });
    }
    return Lightbox;
}());
var LightboxItems = /** @class */ (function () {
    function LightboxItems(items) {
        this.lightbox = $('.c-lightbox');
        this.element = items;
        this.currentImg = this.element;
        this.nextImg = this.currentImg.next();
        this.prevImg = this.currentImg.prev();
        if (!this.lightbox.length)
            return;
        this.element.find('.js-lightbox__inner').on('click', this.open.bind(this));
        this.src = this.element.find('.js-lightbox__inner').attr('href');
        this.type = this.element.attr('data-type');
        this.img = "<div class='c-lightbox__imgwrapper'>\n                        <img unselectable=\"on\" class='c-lightbox__image' src='" + this.src + "' />\n                        <button class='c-lightbox__close c-btn mt-0 c-btn--no-icon  c-btn--square'>\n                            <span class='u-accessibility'>Close</span>\n                            <span class=\"material-symbols-outlined\">\n                              close\n                            </span>\n                        </button>\n                        <button class='c-btn c-lightbox__prev mt-0 c-btn--no-icon  c-btn--square'>\n                \t\t\t\t\t\t<span class='u-accessibility'>back</span>\n                \t\t\t\t\t\t<span class=\"material-symbols-outlined\">\n                \t\t\t\t\t\t\tnavigate_before\n                \t\t\t\t\t\t</span>\n                \t\t\t\t</button>\n                \t\t\t\t<button class='c-btn c-lightbox__next mt-0 c-btn--no-icon  c-btn--square'>\n                \t\t\t\t\t\t<span class='u-accessibility'>next</span>\n                \t\t\t\t\t\t<span class=\"material-symbols-outlined\">\n                \t\t\t\t\t\t\tnavigate_next\n                \t\t\t\t\t\t</span>\n                \t\t\t\t</button>\n                    </div>";
        this.videoElement = "<div class='u-video c-lightbox__video'>\n                                <iframe width='560' height='315' src='" + this.src + "' frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe>\n                                <button class='c-lightbox__close c-btn mt-0 c-btn--no-icon  c-btn--square'>\n                                    <span class='u-accessibility'>Close</span>\n                                    <span class=\"material-symbols-outlined\">\n                                      close\n                                    </span>\n                                </button>\n                            </div>";
    }
    LightboxItems.prototype.open = function (event) {
        event.preventDefault();
        var galleryLength = this.element.parent().find("li").length;
        var index = this.element.index();
        this.lightbox.addClass('u-is-visible');
        $(".c-lightbox__image").attr('src', this.element.find('.js-lightbox__inner').attr('href'));
        if (this.type == 'video') {
            this.lightbox.find('.c-lightbox__inner').append(this.videoElement);
        }
        else if (this.type == 'image') {
            this.lightbox.find('.c-lightbox__inner').append(this.img);
        }
        this.closeBtn = this.lightbox.find('.c-lightbox__close');
        this.closeBtn.on('click', this.close.bind(this));
        this.nextBtn = this.lightbox.find('.c-lightbox__next');
        this.prevBtn = this.lightbox.find('.c-lightbox__prev');
        this.nextBtn.on('click', function () {
            if (index < galleryLength) {
                index++;
            }
            if (index == galleryLength - 1) {
                this.nextBtn.hide();
            }
            if (index > 0) {
                this.prevBtn.show();
            }
            var link = this.element.parent().find("li").eq(index).find("a");
            $(".c-lightbox__image").attr('src', link.attr('href'));
        }.bind(this));
        $(".c-lightbox__image").on('click', function () {
            console.log('text');
            if (index < galleryLength) {
                index++;
            }
            if (index == galleryLength - 1) {
                this.nextBtn.hide();
            }
            if (index > 0) {
                this.prevBtn.show();
            }
            var link = this.element.parent().find("li").eq(index).find("a");
            $(".c-lightbox__image").attr('src', link.attr('href'));
        }.bind(this));
        if (!this.element.prev().length) {
            this.prevBtn.hide();
        }
        if (!this.element.next().length) {
            this.nextBtn.hide();
        }
        this.prevBtn.on('click', function () {
            if (index == 1) {
                this.prevBtn.hide();
            }
            if (index < galleryLength) {
                this.nextBtn.show();
            }
            index--;
            var link = this.element.parent().find("li").eq(index).find("a");
            $(".c-lightbox__image").attr('src', link.attr('href'));
        }.bind(this));
    };
    LightboxItems.prototype.close = function () {
        this.lightbox.removeClass('u-is-visible');
        $('.c-lightbox__inner').empty();
    };
    return LightboxItems;
}());
var lichtbox = new Lightbox();
},{}],5:[function(require,module,exports){
"use strict";
exports.__esModule = true;
var LoadMore = /** @class */ (function () {
    function LoadMore() {
        this.element = $('.js-LoadMore');
        var loadMoreElements;
        this.element.each(function (index, item) {
            var items = $(item);
            var loadMoreElements = new LoadMoreElements(items);
        });
    }
    return LoadMore;
}());
var LoadMoreElements = /** @class */ (function () {
    function LoadMoreElements(items) {
        this.element = items;
        this.items = this.element.find('.js-item');
        this.text = this.element.attr('data-text');
        if (this.items.length > 20) {
            this.element.append("\n                <div class=\"column is-12\">\n                    <button class=\"c-btn c-btn--secondary c-btn--more js-btn\" >\n                        <span>" + this.text + "</span>\n                    </button>\n                </div>\n            ");
            this.items.hide();
            this.items.slice(0, 20).show();
        }
        this.items.hide();
        this.items.slice(0, 20).show();
        this.btn = this.element.find('.js-btn');
        this.btn.on('click', this.more.bind(this));
    }
    LoadMoreElements.prototype.more = function () {
        this.items.filter(':hidden').slice(0, 20).show();
        if (this.items.length == this.items.filter(':visible').length) {
            this.btn.hide();
        }
    };
    return LoadMoreElements;
}());
exports.LoadMoreElements = LoadMoreElements;
var loadmore = new LoadMore();
},{}],6:[function(require,module,exports){
"use strict";
exports.__esModule = true;
require("./menu");
require("./loadMore");
require("./faq");
require("./lightbox");
require("./anchor");
require("./contact");
// import "./cookie";
},{"./anchor":1,"./contact":2,"./faq":3,"./lightbox":4,"./loadMore":5,"./menu":7}],7:[function(require,module,exports){
"use strict";
exports.__esModule = true;
var Menu = /** @class */ (function () {
    function Menu() {
        $('.c-nav-toggle').on('click', this.toggle.bind(this));
        $('main').on('click', this.destroy.bind(this));
        this.swiperPosition();
        // this.height();
        $(window).resize(this.destroy.bind(this));
        // $(window).resize(this.height.bind(this));
        $(window).scroll(this.scroll.bind(this));
        $(window).resize(this.swiperPosition.bind(this));
        $(".has-subNav").each(function (index, item) {
            var items = $(item);
            var subnav = new Subnav(items);
        });
    }
    Menu.prototype.toggle = function (event) {
        event.preventDefault();
        $('body').toggleClass('is-activeNav');
    };
    Menu.prototype.destroy = function (event) {
        if ($('.is-activeNav').length) {
            $('body').removeClass('is-activeNav');
        }
    };
    Menu.prototype.scroll = function () {
        if ($(window).scrollTop() >= 1) {
            $('.c-nav-controls').addClass('c-nav-controls--bg');
        }
        else {
            $('.c-nav-controls').removeClass('c-nav-controls--bg');
        }
    };
    Menu.prototype.swiperPosition = function () {
        var marginRight = ($(window).outerWidth() - $(".js-width").innerWidth()) / 2;
        $('.swiper').css({ "margin-right": "-" + marginRight + "px" });
        if ($(window).width() >= 821) {
            $('.swiper').css({ "margin-right": "-" + marginRight + "px" });
        }
        else if ($(window).width() <= 820) {
            $('.swiper').css({ "margin-right": "0" });
        }
        ;
    };
    return Menu;
}());
var Subnav = /** @class */ (function () {
    function Subnav(items) {
        this.element = items;
        this.element.find(".c-nav__link--main").on('click', this.toggle.bind(this));
        $(".c-subNav--close").on('click', this.close.bind(this));
        $("body").find(".is-subparent").each(function (index, item) {
            var items = $(item);
            var subsubnav = new Subsubnav(items);
        });
    }
    Subnav.prototype.toggle = function (e) {
        e.preventDefault();
        var parentText = this.element[0].innerText;
        $(".is-subnavnew").addClass("is-open-subnav");
        $($(".is-subnavnew").find(".is-subsubNav")[0]).addClass("is-open-subsubnav");
        $($(".is-subnavnew").find(".is-subparent")[0]).addClass("is-open--sub");
    };
    Subnav.prototype.close = function () {
        $(".is-open-subnav").removeClass("is-open-subnav");
        $(".is-open-subsubnav").removeClass("is-open-subsubnav");
    };
    return Subnav;
}());
exports.Subnav = Subnav;
var Subsubnav = /** @class */ (function () {
    function Subsubnav(items) {
        this.element = items;
        this.element.on('click', this.toggle.bind(this));
    }
    Subsubnav.prototype.toggle = function () {
        var parentText = this.element[0].innerText;
        $(".is-open--sub").removeClass("is-open--sub");
        this.element.addClass("is-open--sub");
        $(".is-open-subsubnav").removeClass("is-open-subsubnav");
        $('[data-parent="' + parentText + '"]').addClass("is-open-subsubnav");
    };
    return Subsubnav;
}());
exports.Subsubnav = Subsubnav;
var menu = new Menu();
},{}]},{},[6])

